








import {
  defineComponent,
  watch,
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount
} from '@nuxtjs/composition-api'
import { useContentRouter } from '@wemade-vsf/composables'

export default defineComponent({
  name: 'CmsBlock',
  props: {
    content: {
      type: String,
      default: ''
    },
    styles: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const el = ref(null)
    const { bind, unbind } = useContentRouter()

    onMounted(() => {
      bind(el)
    })

    onBeforeUnmount(() => {
      unbind(el)
    })

    watch(() => props.content, () => {
      nextTick(() => {
          unbind(el)
          bind(el)
      })
    })

    return {
      el
    }
  }
})
